import '../scss/style.scss';

import $ from 'jquery';
import './lib/spNav';

$(function() {
});

window.onload = function () {
};
